.admin-section-card {
  i {
    margin-bottom: 20px;
    color: $grey-light;
    transition: color 0.5s;
  }
  transition: box-shadow 0.5s;

  &:hover {
    i {
      color: $primary;
    }
  }
}
