.dropdown {
  position: relative;
  display: inline-block;

  .menu {
    position: absolute;
    display: none;

    top: 20px;
    z-index: 11;
    min-width: 160px;
    background-color: white;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 5px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);


    &.is-right {
      right: 0;
    }

    .option {
      cursor: pointer;
      padding: 5px 15px;
      display: flex;
      justify-content: space-between;

      &:hover {
        background: darken(white, 5%);
      }
      &:first-child {
        padding-top: 0.5em;
      }
      &:last-child {
        padding-bottom: 0.5em;
      }

      i {
        margin-right: 0.5em;
      }

      span.description {
        margin-left: 1em;
        color: $grey;
        font-style: italic;
      }
    }

    hr {
      height: 1px;
      background-color: rgba(0,0,0,0.1);
      margin: 5px 0;
    }
  }

  .menu.collapsed {
    display: block !important;
  }

  &.actions i {
    padding: 5px 10px;
  }
}