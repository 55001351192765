.field {
  .control.is-danger {
    .input {
      @extend .is-danger;
    }
  }

  &.is-required {
    .label:after {
      color: $danger;
      content: " *";
    }
  }
}
