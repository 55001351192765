.fb {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;

  .fb-row {
    display: flex;
    width: 100%;
    border-bottom: 1px solid $grey-lighter;

    &:hover {
      background-color: $background;
    }

    .fb-cell {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
      overflow: hidden;
      list-style: none;
      padding: 0.2em 0.2em;
      width: 8rem;

      &.cell-selection {
        width: 4rem;
        flex-grow: 0;
      }
      &.cell-icon {
        width: 4rem;
        flex-grow: 0;
      }
      &.cell-name {
        width: 12rem;
        flex-grow: 3;
      }

      .cell__description * {
        color: $grey-light;
        font-style: italic;
        font-size: 0.8rem;
        overflow: hidden;
      }
    }

    &.fb-header {
      font-weight: bold;
    }

    .selection-input {
      display: block;
      height: 1.2rem;
      width: 1.2rem;

      margin: 0 auto;
      text-align: center;
      line-height: 1.2rem;
      font-size: 0.6rem;

      border-radius: 50%;

      &.is-selected {
        background-color: $link-light;
        color: #307db0;
        border: 1px solid rgba(50, 130, 184, 0.2);
      }
    }
    &:hover {
      .selection-input {
        border: 1px solid rgba(50, 130, 184, 0.4);
      }
    }
  }

  i.fb-icon {
    img {
      width: 2rem;
      height: 2rem;
    }
  }
}

.breadcrumbs {
  margin: 2rem 0 0 0;
  .breadcrumbs-name {
    padding: 0.2em 0.4em;
  }
}

.configuration-select {
  display: flex;
  overflow-x: auto;

  &.is-centered {
    justify-content: center;
  }

  .configuration-tile {
    flex-grow: 0;
    flex-shrink: 0;

    border: 1px solid $grey-lighter;
    border-radius: 5px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    width: 12rem;
    padding: 0.5rem;
    margin: 0.5rem;

    text-align: center;

    i,
    img {
      width: 6rem;
      height: 6rem;
    }

    &:hover {
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
    }

    &.is-selected {
      box-shadow: $selection-active-shadow;
    }
  }
}

.is-clickable:hover {
  text-decoration: underline;
  cursor: pointer;
}

// TODO: Responsive
