@import '~bulma';

$primary: #003040;
$primary-lighter: lighten($primary, 5%);
$primary-light: #3282b8;

$dark: #032926;
$grey-dark: #3F4C54;
$grey: #4C5C66;
$grey-light: lighten($grey, 10%);
$light: #A4ABB0;

$success: #009566;
$success-light: lighten($success, 10%);
$success-dark: darken($success, 10%);

$danger: #DC042B;
$danger-dark: #B50F24;

$warning: #F39541;
$warning-light: #FDC318;

$info: #3282b8;
$info-light: lighten($info, 50%);

$link: #3282b8;
$link-hover: darken($link, 10%);

$current: $link-hover;
$white-bis: #f0f7f9;

.tag.is-danger.is-light {
  border: 1px solid rgba($danger, 0.2);
}
.tag.is-info.is-light {
  border: 1px solid rgba($info,0.2);
}

$landing-background: $grey linear-gradient(-45deg, $primary, $grey);
$layout-background: $white-bis;
$footer-background-color: $white-bis;

$button-navigation-color: $white;
$button-navigation-background: $grey-light;

$selection-background: $white-bis;
$selection-shadow: $card-shadow;
$selection-color: $card-color;
$selection-active-color: $white-bis;
$selection-active-background: $link;
$selection-active-shadow: $box-link-hover-shadow;
$selection-hover-shadow: $box-link-hover-shadow;
