.entity-browser {
  $border-width: 8px;
  .columns {
    padding: 0.75rem;
  }

  .column {
    padding: 0;
  }

  .entity {
    padding: 15px;
    margin: 5px;

    display: flex;
    flex-direction: row;

    border-radius: 5px;

    > * {
      display: flex;
      flex-direction: column;
    }

    .icon {
      width: 42px;
      height: 42px;
      font-size: 42px;
    }

    .entity-info {
      margin-left: 12px;
      flex-grow: 1;
    }

    .actions i {
      margin-top: 12px;
    }

    &.selected {
      border-left: $border-width solid $primary-light !important; // Important needed in order to overlay the selection
    }

    &.folder {
      border-left: $border-width  solid #f4f4f4;
    }

  }

  .entity:not(.parent) {
    background: #fafafa;
    border-left: $border-width  solid #fafafa;

    &:hover {
      background-color: #f4f4f4;
      border-left: $border-width  solid #f4f4f4;
    }

    .icon {

      cursor: pointer;
    }
  }

  .entity.parent .entity-info {
    flex-direction: row;

    .spacer {
      padding: 0 15px;
    }

    span.parent {
      cursor: pointer;
    }
  }

  .entity.folder:not(.parent) {
    border-left: $border-width solid #f4f4f4;
  }
}