.button.confirm {
  // the animation works by sliding a background 'gradient' over the button.
  // the gradient looks like: [ <light> 50% <dark> ] and moves to the right.
  // by making it 2x as wide as the button, we can move from fully light to fully dark
  background-size: 200%;
  background-position: 100%;
  // the timing here is 0s so that the animation fully resets as soon as the button is no longer animating
  transition: background-position 0s linear;

  &.is-danger.is-light.is-animating {
    $lighter-color: lighten($danger-light, 10%);
    $normal-color: $danger-light;
    background-image: linear-gradient(
      to right,
      $normal-color 0%,
      $normal-color 50%,
      $lighter-color 50%,
      $lighter-color 100%
    );
  }

  &.is-warning.is-light.is-animating {
    $lighter-color: lighten($warning-light, 10%);
    $normal-color: $warning-light;
    background-image: linear-gradient(
      to right,
      $normal-color 0%,
      $normal-color 50%,
      $lighter-color 50%,
      $lighter-color 100%
    );
  }

  &.is-info.is-info.is-animating {
    $lighter-color: lighten($info-light, 10%);
    $normal-color: $info-light;
    background-image: linear-gradient(
      to right,
      $normal-color 0%,
      $normal-color 50%,
      $lighter-color 50%,
      $lighter-color 100%
    );
  }

  &.is-animating {
    // slowly move the background to the right
    transition-duration: 5s;
    background-position: 0;
  }
}
