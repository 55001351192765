.language-settings {
  .is-multiselect .field .control {
    display: flex;
    flex-direction: row;
    align-items: center;
    .select {
      flex-grow: 1;
      flex-basis: 0;

      select {
        width: 100%;
        padding-left: 0;
        height: 200px;
      }

      &:after {
        display: none;
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0;

      .button {
        margin: 10px;
      }
    }
  }
}
