.navbar {
  background-color: $primary;

  .navbar-menu .navbar-start .navbar-item {
    color: $white;

    &:hover {
      background-color: $primary-lighter;
    }

    &.active {
      background-color: $primary-lighter;
    }
  }

  .navbar-menu.is-active .navbar-start .navbar-item {
    &:hover {
      color: $white !important;
    }

    &.active {
      color: $white !important;
    }
  }

  .navbar-end .buttons button {
    color: $button-navigation-color;
    background-color: $button-navigation-background;
    border: 1px solid darken($button-navigation-background, 10%);
  }

  .navbar-burger {
    background-color: transparent;
    border: none;
    outline: none;
  }

  i {
    margin-right: 5px;
  }

  @include touch() {
    .navbar-menu {
      display: block;
      overflow: hidden;
      transition: max-height 0.6s, padding 0.6s;
      height: 100%;
      max-height: 0;
      position: fixed;
      width: 100%;
      padding: 0;

      &.is-active {
        max-height: 90%;
        padding: 8px 0;
      }
    }
  }
}
