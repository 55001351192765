.app-layout {
  background-color: $layout-background;
  min-height: 100vh;

  .main {
    margin-top: 25px;
    @include touch() {
      margin: 25px 12px;
    }
  }
}
