table.table {
  tr {
    width: 100%;
    td {
      max-width: 200px;
      vertical-align: middle;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    td.text-wrap {
      overflow: hidden;
    }
  }

  @include mobile() {
    // on mobile we want to view the table rows as cards
    &, thead, tbody, tfoot, th, td, tr {
      display: block;
    }
    thead {
      display: none;
    }
    tr {
      td {
        max-width: 100%;
        border: none;
        overflow: auto;
      }
    }
    tbody {
      tr {
        background-color: $white;
        margin: 12px;
        box-shadow: $box-shadow;

        td:first-child {
          font-weight: bold;
          border-bottom: $border 1px solid;
        }
      }
    }
  }
}
