tr.is-dragging {
  filter: alpha(20%);
  box-shadow: 1px 1px 20px $success inset;
}

tr.no-drag {
  filter: alpha(20%);
  box-shadow: 1px 1px 20px $danger inset;
}

.jobs-info .actions {
  margin-bottom: 1rem;
}