.source-section-card {
  box-shadow: $selection-shadow;
  cursor: pointer;
  transition: border 0.5s, box-shadow 0.5s;
 
  i {
    margin-bottom: 20px;
    color: $selection-color;
    transition: color 0.5s;
  }

  &.is-disabled {
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2) inset;
    background-color: $selection-background;
    cursor: not-allowed;
  }

  &:hover:not(.is-disabled) {
    box-shadow: $selection-hover-shadow;
  }

  &.is-selected {
    background: $selection-active-background;
    box-shadow: $selection-active-shadow;
    transition: background-color 0.5s;
    * {
      color: $selection-active-color;
    }
  }
}

.general-overview .overview-line,
.asset-overview .asset-info {
  padding: 15px;
  border-bottom: 1px solid $grey-lighter;

  .tags {
    margin: 0 5px;
  }

  &:nth-child(even) {
    background-color: $white-bis;
  }

  &:last-child {
    border-bottom: none;
  }

  &.header {
    font-weight: bold;
  }
}

.asset-overview {
  max-height: 500px;
  margin-bottom: 20px;
  overflow: scroll;

  .tags.is-down span.message {
    top: 2rem;
    bottom: auto;
  }
}
