.messages {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;

  margin: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;

  pointer-events: none;

  .message {
    position: relative;
    padding: 12px 24px;
    margin: 0.25rem;
    background-color: #ffffff;
    border: 1px solid rgba(0,0,0,0.1);
    box-shadow: 1px 1px 10px rgba(0,0,0,0.2);

    animation: message-show 0.5s normal forwards ease-in-out;

    pointer-events: all;

    i {
      margin-right: 12px;
    }
  }

  @keyframes message-show {
    from { top: -96px; opacity: 0; }
    to { top: 0; opacity: 1; }
  }
}

