.checkbox-list {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 180px;
  height: 200px;
  padding: 10px 10px;
  vertical-align: middle;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin:5%;
  overflow:hidden; overflow-y:scroll;
 }

.buttons {
  flex-direction: column;
  align-items: center;
}

button.transfer-button {
  margin:20%;
}

input.checkbox-input {
  margin: 5%;
}

label.checkbox-label {
  margin-right: 20%;
}

.buttons.is-pulled-right {
  margin-bottom: 0%;
  display:flex;
  flex-direction: row;
  margin-right: 0%;
}


