.providers {
    padding-top: 2rem;
    h1 {
        //margin-top: 10px;
        color: #3282B8;
        padding-left: 3rem;
        font-weight: normal;
        font-size: 22px;
    }
    .columns {
        width: 100%;
        padding: 2rem;
    }
    .provider {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.2em;
        text-align: center;
        
        i, img {
            width: auto;
            height: 4rem;
        }

        span {
            color: black;
            width: 18rem;
        }

        &:hover {
            border: 1px solid $info;
            border-radius: 5px;
            background-color: $white-bis;
            box-shadow: 1px 1px 10px rgba($info, 0.2);
        }
    }
}
