.asset-table {
  max-height: 500px;
  overflow-y: scroll;
  margin-bottom: 20px;
  table{
    position:relative;
  }

  thead th {
    position: sticky;
    top: 0;
    background-color: $white;
    border: none;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.2);
  }
}
