.landing-page .container p {
  color: black;
  font-weight: normal;
}

.landing-page .container strong {
  color: $info;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.0rem;
  display: block;
  text-align: center;
  margin-top: 5%;

}
