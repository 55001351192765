.tabs.is-boxed {
  margin-bottom: 0;
}

.tab-content.box {
  border: $tabs-border-bottom-color $tabs-border-bottom-style $tabs-border-bottom-width;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: none;
}
