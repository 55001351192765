@import 'colors.scss';

@import '~bulma';
@import '~bulma-pageloader';
@import '~bulma-switch';
@import '@creativebulma/bulma-tooltip';
@import '~@fortawesome/fontawesome-free/css/all.min.css';

#root {
  @import 'components/Tooltip/Tooltip';
  @import 'components/JobStatus/JobStatus';
  @import 'components/AppLayout/AppLayout';
  @import 'components/AssetsTable/AssetsTable';
  @import 'components/ConfirmButton/ConfirmButton';
  @import 'components/Dropdown/Dropdown';
  @import 'components/EntityBrowser/EntityBrowser';
  @import 'components/EntityOverview/EntityOverview';
  @import 'components/Input/Field';
  @import 'components/Input/TextField';
  @import 'components/LandingLayout/LandingLayout';
  @import 'components/LanguageSettings/LanguageSettings';
  @import 'components/NavBar/NavBar';
  @import 'components/Message/Message';
  @import 'components/Pagination/Pagination';
  @import 'components/Property/Property';
  @import 'components/Table/Table';
  @import 'components/Tabs/Tabs';
  @import 'pages/Administration/AdminPage/AdminPage';
  @import 'pages/Administration/ConnectorsPage/ConnectorsPage.scss';
  @import 'pages/Administration/ProvidersPage/ProvidersPage.scss';
  @import 'pages/Overview/ProjectEditPage/ProjectEditPage';
  @import 'components/Input/Transfer';
  @import 'pages/Overview/TaskCreatePage/TaskCreatePage';
  @import 'pages/Overview/JobInfoPage/JobInfoPage';
  @import 'pages/Authentication/ForgotPasswordPage/ForgotPasswordPage';
  @import 'components/Task/TaskSummary/AssetOverviewTable';
  @import 'pages/Common/ErrorPage/ErrorPage.scss';
  @import 'pages/Common/LoadingPage/LoadingPage.scss';
}

@import 'components/FileBrowser/FileBrowser';
