.connectors {
    .columns {
        width: 100%;
        padding: 2rem;
    }
    .connector {
        display: flex;
        align-items: center;
        font-size: 1.2em;
        
        i, img {
            width: 4rem;
            height: 4rem;
        }

        span {
            padding-left: 2rem;
            color: $box-color;
        }

        &:hover {
            border-radius: 5px;
            background-color: $box-background-color;
            box-shadow: $box-link-hover-shadow; 
        }
    }
}
