.tooltip {
  position: relative;

  .message {
    opacity: 0;

    position: absolute;
    z-index: -999;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);

    box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.4);
    background-color: rgba(0,0,0,0.8);
    color: $success-invert;
    padding: 15px;
    white-space: normal;
    text-align: center;

    transition: opacity 0.2s ease-out;
  }

  &.is-visible .message {
    opacity: 1;
    z-index: 999;
  }

  &.tooltip-right .message {
    left: 100%;
    transform: translate(-100%, 0);
    width:100px;
  }

  &.tooltip-left .message {
    left: 0;
    width:100px;
    transform: translate(0, 0);
  }

  &.tooltip-big .message {
    width:300px;
  }
}
