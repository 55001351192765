
.landing-page {
  background: $landing-background; 
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .welcome {
    display: none; // hide this by default
    color: $primary-invert;
    font-weight: bold;
    font-size: 0.9em;

    img.logo {
      width: 100%;
      margin-bottom: 15px;
    }

    img.decal {
      float: right;
      margin-right: -100px;
    }
  }

  .content {
    padding: 20px;

    h2 {
      color: $primary-invert;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 18px;
      margin-bottom: 20px;
      margin-left: 20px;

      img {
        height: 18px;
      }
    }

    p {
      color: white;
      color: $primary-invert;
      font-weight: bold;
      font-size: 0.9em;
    }

    form {
      margin-top: 30px;

      .field.is-required {
        .label:after {
          display: none;
        }
      }
    }

    .controls {
      margin-top: 30px;
    }
  }

  .content, .welcome {
    width: 400px;

    .field.is-grouped-wide {
      @extend .is-grouped;
      justify-content: space-between;
    }
  }

  @include desktop() {
    .welcome {
      // You're on desktop! You may see the .welcome message now.
      display: block;
    }
  }
}
